import godla from "./assets/godla.jpg";
import { useState } from "react";

export default function App() {
  const [formData, setFormData] = useState({
    email: "",
  });

  const mailData = {
    list: "https://whereisgodinla.us1.list-manage.com/subscribe/post",
    u: "805e834246003d35d0398947f",
    id: "6b0fc7940a",
    spam: "b_805e834246003d35d0398947f_6b0fc7940a",
  };

  return (
    <div id="home">
      <div className="d-flex flex-column position-relative vh-100">
        <img
          src={godla}
          alt="god in la"
          className="img-fluid h-100"
          style={{ objectFit: "cover" }}
        />
      </div>
      <div className="d-flex flex-column position-absolute top-0 w-100 h-100 text-center">
        <h1 className="display-1 mt-5">Where is God in LA?</h1>
        <p className="lead mt-auto mb-auto mx-3 mx-md-5 pt-5">
          A documentary about spirituality in the world's most diverse and
          eclectic city.
        </p>
        <form
          className="mt-3 mt-lg-5 mx-auto d-flex flex-column"
          action={mailData.list}
          method="POST"
          noValidate
        >
          <input type="hidden" name="u" value={mailData.u} />
          <input type="hidden" name="id" value={mailData.id} />
          <div className="row mb-2">
            <div className="col">
              <div className="form-floating">
                <input
                  className="form-control bg-transparent"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  value={formData.email}
                  placeholder="Email address"
                  type="email"
                  name="EMAIL"
                  id="MERGE0"
                />
                <label htmlFor="email">Email address</label>
              </div>
            </div>
          </div>

          <input
            type="submit"
            value="Sign up for updates"
            className="btn btn-transparent border px-5 mb-5"
          />

          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
            aria-label="Please leave the following field empty"
          >
            <label htmlFor={mailData.spam}>Name:</label>
            <input
              type="text"
              name={mailData.spam}
              tabIndex="-1"
              defaultValue=""
            />
          </div>
        </form>
      </div>
    </div>
  );
}
